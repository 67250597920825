import { useCallback, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Field, FieldButton, Form, useForm } from '@rpgtec/use-form'

import { sendPasswordResetEmail } from 'hooks/useAuth'
import { ReactComponent as CloseIcon } from 'icons/close.svg'

export default function ForgotPassword({ onClose }) {
  const [error, setError] = useState(false)
  const [email, setEmail] = useState({ sent: false, value: null })
  const form = useForm({ defaultValues: {} })

  const handleSubmit = useCallback(({ email }) => {
    setError(null)
    return sendPasswordResetEmail(email)
      .then(() => setEmail({ sent: true, value: email }))
      .catch(setError)
  }, [])

  return (
    <>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: theme => theme.spacing(3),
          top: theme => theme.spacing(3),
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>パスワード再設定</DialogTitle>
      {email.sent ? (
        <Box sx={{ pb: 3 }}>
          <Typography sx={{ px: 3, pb: 3 }}>
            リセットメールを以下に送信しました。
            <br />
            {email.value}
            <br />
            メールをご確認ください。
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button onClick={() => setEmail({ sent: false, value: null })}>戻る</Button>
          </Box>
        </Box>
      ) : (
        <>
          <Typography sx={{ px: 3, pb: 3 }}>
            パスワードリセットのご案内を送信する、メールアドレスを入力してください。
          </Typography>
          <Form
            form={form}
            onSubmit={handleSubmit}
            sx={{
              px: 3,
              pb: 3,
              '& .MuiTextField-root': { mb: 2 },
            }}
          >
            <Field
              name="email"
              label="メールアドレス"
              rules={{
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              }}
              errorText={{
                required: '必須項目です',
                pattern: 'メールアドレスの形式で入力してください',
              }}
              autoFocus
            />
            {error && (
              <Box sx={{ mb: 2 }}>
                <FormHelperText error>{error.message}</FormHelperText>
              </Box>
            )}
            <Box display="flex" justifyContent="center">
              <FieldButton type="submit" variant="contained">
                リセット用メールを送信
              </FieldButton>
            </Box>
          </Form>
        </>
      )}
    </>
  )
}
