import Fab from '@mui/material/Fab'

import { ReactComponent as ShareIcon } from 'icons/share.svg'

export default function ShareAction({ sx }) {
  if (!navigator.share) return null

  const handleClick = () => {
    navigator.share({
      url: 'https://pinklive.the-pink.club/live',
      title: 'thepinkLive',
      text: '大阪ミナミの進化系クラブ、The Pink のオンライン動画配信サイトです。',
    })
  }

  return (
    <Fab size="small" style={{ background: 'rgba(0,0,0,.2)', ...sx }} onClick={handleClick}>
      <ShareIcon />
    </Fab>
  )
}
