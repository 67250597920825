import { cyan, grey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'

const primary = '#d84e8f'
const textPrimary = grey[900]
const textSecondary = grey[700]

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: cyan,
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    background: {
      default: '#242424',
      paper: '#fff',
    },
    divider: grey[300],
  },
  spacing: 8,
  typography: {
    body1: { fontSize: 14, color: textPrimary },
    body2: { fontSize: 14, color: textSecondary },
    caption: { fontSize: 10, color: textSecondary },
    link: {
      fontSize: 14,
      color: '#2e7cf6',
      cursor: 'pointer',
    },
    fontFamily: [
      '"Hiragino Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 600,
      lg: 900,
      xl: 1200,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          paddingLeft: 24,
          paddingRight: 24,
          fontWeight: 'bold',
        },
        outlined: {
          border: `2px solid ${primary}`,
          background: 'rgba(255,255,255,0.8)',
          boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.08), 0px 4px 10px rgba(0, 0, 0, 0.12)',
          '&:hover': {
            border: `2px solid ${primary}`,
            background: 'rgba(255,255,255,0.9)',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
          '& .MuiDialogTitle-root': {
            padding: 24,
            fontSize: 18,
            fontWeight: 'bold',
            height: 88,
            display: 'flex',
            alignItems: 'center',
          },
        },
        paperFullScreen: {
          borderRadius: 0,
          '& .MuiDialogTitle-root': {
            padding: '8px 16px',
            height: 56,
          },
        },
      },
    },
  },
})

export default function Theme({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
