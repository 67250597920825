import React, { createContext, useContext } from 'react'

import { useDoc } from 'lib/firestore'

const AppContext = createContext()

export function AppProvider({ children }) {
  const { item, error, loading } = useDoc('shared/data')

  return (
    <AppContext.Provider
      value={{
        event: Boolean(item && item.event) ? item.event : null,
        products: Boolean(item && item.products) ? item.products : [],
        toxicWords: Boolean(item && item.toxicWords) ? item.toxicWords : [],
        error,
        loading,
        loaded: item !== null,
      }}
      children={children}
    />
  )
}

export default function useApp() {
  return useContext(AppContext)
}
