import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'

import { ReactComponent as CloseIcon } from 'icons/close.svg'
import placeholder from 'images/placeholder.jpg'
import { formatPrice } from 'utils'

const MAX_WIDTH = 480 - 64

export default function PaymentSuccessDialog({ item, onClose }) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
    setTimeout(() => setOpen(false), 2000)
  }, [])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Slide}
      TransitionProps={{ timeout: { enter: 300, exit: 300 }, onExited: onClose }}
      sx={{ '& .MuiPaper-root': { maxWidth: MAX_WIDTH } }}
    >
      <IconButton
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: theme => theme.spacing(1),
          top: theme => theme.spacing(1),
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box
        sx={{
          width: { xs: 'calc(100vw - 64px)', sm: MAX_WIDTH },
          height: { xs: 'calc(100vw - 64px)', sm: MAX_WIDTH },
          '& img': {
            width: { xs: 'calc(100vw - 64px)', sm: MAX_WIDTH },
            height: { xs: 'calc(100vw - 64px)', sm: MAX_WIDTH },
            objectFit: { xs: 'cover', sm: 'contain' },
          },
        }}
      >
        <img src={item.image} alt={item.name} onError={event => (event.target.src = placeholder)} />
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold', mb: 2 }}>{item.name}</Typography>
        <Typography sx={{ fontSize: 12, mb: 1 }}>
          <span style={{ fontSize: 14, fontWeight: 'bold', marginRight: 2 }}>
            {formatPrice(item.price)}円
          </span>
          を支払いました！
        </Typography>
        <Typography sx={{ fontSize: 12, color: 'grey.500' }}>
          お支払いいただきありがとうございます。
          <br />
          本商品はお客様への配送はございません。
          <br />
          購入した商品はこの配信中に店舗で振る舞われ、お客様には感謝メッセージが届きます。
        </Typography>
      </Box>
    </Dialog>
  )
}
