import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'

import useAuth from 'hooks/useAuth'
import { setMute } from 'Live/Vimeo'

export default function VerifyEmail({ oobCode, onClickResend, onSuccess }) {
  const [error, setError] = useState(null)
  const { verifyEmail, user } = useAuth()

  useEffect(() => {
    if (oobCode && user && !user.emailVerified) {
      verifyEmail(oobCode).catch(setError)
    }
  }, [oobCode, user, verifyEmail])

  const handleClick = () => {
    setMute(false)
    onSuccess()
  }

  if (error) {
    return (
      <>
        <DialogTitle>メールアドレスの確認に失敗しました</DialogTitle>
        <Box sx={{ pb: 3, px: 3 }}>
          <Typography sx={{ pb: 2 }}>
            メールを再送信して最新のメールからリンクを開いて下さい。
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" onClick={onClickResend}>
              メールの再送信
            </Button>
          </Box>
        </Box>
      </>
    )
  }

  if (user && user.emailVerified) {
    return (
      <>
        <DialogTitle>メールアドレスを確認しました</DialogTitle>
        <Box sx={{ pb: 3, px: 3 }}>
          <Typography sx={{ pb: 2 }}>
            ユーザー登録ができました。 thepinkLive をお楽しみ下さい。
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" onClick={handleClick}>
              はじめる
            </Button>
          </Box>
        </Box>
      </>
    )
  } else {
    return null
  }
}
