import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Field, FieldButton, Form, useForm } from '@rpgtec/use-form'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { logEvent } from 'lib/analytics'
import { httpsCallable } from 'lib/functions'
import { formatPrice } from 'utils'

const stripePromise = loadStripe(
  'pk_live_51JbgiAHBlfV2kNiOGWxueBIy80xtU8dwWqKPaMUAi1zHpI3quabIRCtmLyLyOyi5uYQb9fvlMUNCi8r4TxzJacD0002KqqEszw',
)

function StripeForm({ product, onSuccess }) {
  const form = useForm({ defaultValues: { save: true } })
  const stripe = useStripe()
  const elements = useElements()
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [selected, setSelected] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    httpsCallable('callable-stripe-getPaymentMethods')()
      .then(res => setPaymentMethods(res.data.data))
      .catch(() => setPaymentMethods([]))
  }, [])

  const submitDisabled = useMemo(() => {
    if (!stripe || !elements) return true
    if (paymentMethods === null) return true
    if (paymentMethods.length > 0 && selected === '') return true
    return false
  }, [stripe, elements, paymentMethods, selected])

  const pay = data => {
    return httpsCallable('callable-stripe-pay')({
      ...data,
      product,
    }).then(res => {
      const { requiresAction, secret } = res.data

      if (requiresAction) {
        return stripe.handleCardAction(secret).then(result => {
          if (result.error) {
            throw result.error
          }
          return pay({ paymentIntentId: result.paymentIntent.id })
        })
      }
    })
  }

  const handleSubmit = async ({ name, save }) => {
    logEvent('payment', { type: 'submit', name: product.name, price: product.price })
    try {
      if (['new', ''].includes(selected)) {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement),
          billing_details: { name },
        })
        if (result.error) {
          throw new Error(result.error.message)
        }
        await pay({ paymentMethodId: result.paymentMethod.id, save })
      } else {
        await pay({ paymentMethodId: selected })
      }

      logEvent('payment', { type: 'success', name: product.name, price: product.price })
      onSuccess()
    } catch (error) {
      logEvent('payment', {
        type: 'payment-error',
        name: product.name,
        price: product.price,
        message: error.message,
      })
      setError(error)
    }
  }

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Box sx={{ p: 2, mb: '68px' }}>
        <Typography sx={{ fontWeight: 'bold', mb: 1, fontSize: 16 }}>お支払い方法</Typography>
        {paymentMethods === null && (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: 80 }}>
            <CircularProgress />
          </Box>
        )}
        {(paymentMethods || []).length > 0 && (
          <FormControl>
            <RadioGroup value={selected} onChange={e => setSelected(e.target.value)}>
              {(paymentMethods || []).map(x => (
                <FormControlLabel
                  key={x.id}
                  value={x.id}
                  control={<Radio />}
                  sx={{ mb: 1, height: '56px' }}
                  label={
                    <Box sx={{ ml: 2 }}>
                      <Typography component="span">{x.card.brand.toUpperCase()}</Typography>
                      <Typography component="span" sx={{ ml: 2, mr: 1 }}>
                        ●●●●
                      </Typography>
                      <Typography component="span">{x.card.last4}</Typography>
                    </Box>
                  }
                />
              ))}
              <FormControlLabel
                value="new"
                control={<Radio />}
                sx={{ mb: 1, height: '56px' }}
                label={<Typography sx={{ ml: 2 }}>新しいカード</Typography>}
              />
            </RadioGroup>
          </FormControl>
        )}
        {((paymentMethods && paymentMethods.length === 0) || selected === 'new') && (
          <>
            <Field
              name="name"
              label="カード名義"
              fullWidth
              rules={{ required: true }}
              InputLabelProps={{ shrink: true }}
              sx={{ mb: 2 }}
            />
            <TextField
              name="cardNumber"
              label="カード番号"
              fullWidth
              inputProps={{ Component: CardNumberElement }}
              InputProps={{ inputComponent: StripeInput }}
              InputLabelProps={{ shrink: true }}
            />
            <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 1, gap: 2 }}>
              <TextField
                name="cardExpiry"
                label="有効期限"
                fullWidth
                inputProps={{ Component: CardExpiryElement }}
                InputProps={{ inputComponent: StripeInput }}
                InputLabelProps={{ shrink: true }}
                sx={{ flex: 1 }}
              />
              <TextField
                name="cardCvc"
                label="セキュリティコード"
                fullWidth
                inputProps={{ Component: CardCvcElement }}
                InputProps={{ inputComponent: StripeInput }}
                InputLabelProps={{ shrink: true }}
                sx={{ flex: 1 }}
              />
            </Box>
            <Field
              name="save"
              label="お支払い方法を保存する"
              labelPlacement="start"
              type="checkbox"
              sx={{
                height: 56,
                display: 'flex',
                px: 2,
                mx: 0,
                '& .MuiTypography-root': { flex: 1 },
              }}
            />
          </>
        )}
        {Boolean(error) && (
          <FormHelperText error sx={{ px: 2 }}>
            {error.message}
          </FormHelperText>
        )}
        <Box
          display="flex"
          flexDirection="row-reverse"
          alignItems="center"
          sx={{
            position: 'fixed',
            px: 2,
            bottom: 0,
            left: 0,
            right: 0,
            height: 68,
            zIndex: 100,
            borderTop: theme => `1px solid ${theme.palette.divider}`,
            bgcolor: 'background.paper',
          }}
        >
          <FieldButton type="submit" variant="contained" disabled={submitDisabled}>
            {formatPrice(product.price)}円を支払う
          </FieldButton>
        </Box>
      </Box>
    </Form>
  )
}

export default function Stripe(props) {
  return (
    <Elements stripe={stripePromise}>
      <StripeForm {...props} />
    </Elements>
  )
}

const StripeInput = React.forwardRef(({ Component, ...other }, ref) => {
  const componentRef = useRef()

  useImperativeHandle(ref, () => ({
    focus: () => componentRef.current.focus,
  }))

  return <Component onReady={element => (componentRef.current = element)} {...other} />
})
