import { useEffect, useMemo, useState } from 'react'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import ArrowLeftIcon from 'icons/ArrowLeftIcon'
import { ReactComponent as MinusIcon } from 'icons/minus.svg'
import { ReactComponent as PlusIcon } from 'icons/plus.svg'
import placeholder from 'images/placeholder.jpg'
import { formatPrice } from 'utils'

import Stripe from './Stripe'

export default function ProductDialog({ product, onSuccess, onClose }) {
  const [open, setOpen] = useState(false)
  const [amount, setAmount] = useState(1)

  useEffect(() => {
    setOpen(true)
  }, [])

  const { name, image } = useMemo(() => {
    if (amount === 1) {
      return product
    }
    const match = product.items
      .sort((a, b) => (a.amount < b.amount ? 1 : -1))
      .find(x => amount >= x.amount)
    return Boolean(match) ? match : product
  }, [product, amount])

  const price = useMemo(() => {
    return product.price * amount
  }, [product, amount])

  const handleClickPlus = () => {
    let diff = 1
    if (amount >= 100) {
      diff = 100
    } else if (amount >= 10) {
      diff = 10
    }
    setAmount(amount + diff)
  }

  const handleClickMinus = () => {
    let diff = 1
    if (amount > 100) {
      diff = 100
    } else if (amount > 10) {
      diff = 10
    }
    setAmount(amount - diff)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: onClose }}
      fullScreen
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: 56,
          background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
        }}
      >
        <IconButton sx={{ ml: 2 }} onClick={() => setOpen(false)}>
          <ArrowLeftIcon color="#000" />
        </IconButton>
        <Typography noWrap sx={{ flex: 1, ml: 1, fontSize: 18, fontWeight: 'bold' }}>
          お支払い
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100vw',
          height: { xs: '100vw', sm: 480 },
          '& img': {
            margin: '16px',
            width: 'calc(100vw - 32px)',
            height: { xs: 'calc(100vw - 32px)', sm: 480 - 32 },
            objectFit: { xs: 'cover', sm: 'contain' },
          },
        }}
      >
        <img src={image} alt={name} onError={event => (event.target.src = placeholder)} />
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold', mb: 2 }}>{name}</Typography>
        {product.amount && product.amount > 1 ? (
          <>
            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold', flex: 1 }}>
                {formatPrice(price)}円
              </Typography>
              <PriceIconButton disabled={amount <= 1} onClick={handleClickMinus} sx={{ mr: 1 }}>
                <MinusIcon />
              </PriceIconButton>
              <PriceIconButton disabled={amount >= product.amount} onClick={handleClickPlus}>
                <PlusIcon />
              </PriceIconButton>
            </Box>
            <Typography>お客様には感謝メッセージが届きます。</Typography>
          </>
        ) : (
          <Typography>
            購入した商品はこの配信中に店舗で振る舞われ、お客様には感謝メッセージが届きます。
          </Typography>
        )}
      </Box>
      <Stripe
        product={{ id: product.id, name, price }}
        onSuccess={() => onSuccess({ name, image, price })}
      />
    </Dialog>
  )
}

const PriceIconButton = ({ children, onClick, sx, ...props }) => (
  <IconButton
    sx={{
      bgcolor: 'grey.300',
      borderRadius: '8px',
      '&:hover': { bgcolor: 'grey.300' },
      '&.Mui-disabled': { opacity: 0.4, bgcolor: 'grey.300' },
      '& svg': { width: 16, height: 16 },
      ...sx,
    }}
    onClick={onClick}
    {...props}
  >
    {children}
  </IconButton>
)
