import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

const app = initializeApp({
  apiKey: 'AIzaSyC6zHfzzy703evNWVKQxOciDWTnpmWB2pY',
  authDomain: 'the-pink.firebaseapp.com',
  projectId: 'the-pink',
  storageBucket: 'the-pink.appspot.com',
  messagingSenderId: '1070364623132',
  appId: '1:1070364623132:web:ad9a0a5a1f7dff0fe8bad9',
  measurementId: 'G-BBPRL3BDMW',
})

const db = getFirestore(app)
const functions = getFunctions(app, 'asia-northeast1')
const auth = getAuth(app)
const storage = getStorage(app)

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
  connectStorageEmulator(storage, 'localhost', 9199)
}

export { auth, db, functions, storage }
