import { useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import AuthDialog from 'components/AuthDialog'
import useApp from 'hooks/useApp'
import useAuth from 'hooks/useAuth'

import AppBar from './AppBar'
import LoginAction from './LoginAction'
import MessageAction from './MessageAction'
import MessageList from './MessageList'
import PaymentAction from './PaymentAction'
import ShareAction from './ShareAction'
import Snackbar from './Snackbar'
import Vimeo from './Vimeo'

export default function Live() {
  const { user } = useAuth()
  const { event } = useApp()
  const [openForm, setOpenForm] = useState(false)

  return (
    <>
      {Boolean(event) && event.streaming ? (
        <Vimeo />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            '& .MuiTypography-root': { color: '#fff' },
          }}
        >
          {Boolean(event) && (
            <>
              <Typography sx={{ fontSize: 16, mb: 0.5 }}>
                {dayjs(event.start).format('YYYY/MM/DD HH:mm')}
              </Typography>
              <Typography sx={{ fontSize: 12, mb: 3 }}>から始まります</Typography>
            </>
          )}
          <Typography sx={{ fontSize: 16 }}>しばらくお待ち下さい</Typography>
        </Box>
      )}
      <Box
        sx={{
          position: 'absolute',
          top: 56,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 80,
        }}
      />
      <AppBar />
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: 200,
          zIndex: 50,
          background:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.54) 3.12%, rgba(0, 0, 0, 0.42) 35.94%, rgba(0, 0, 0, 0.249316) 73.96%, rgba(0, 0, 0, 0) 100%)',
        }}
      />
      <MessageList
        sx={{
          position: 'absolute',
          left: 0,
          right: 80,
          bottom: 72,
          height: 152,
          zIndex: 100,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: 72,
          zIndex: 100,
        }}
      >
        {Boolean(user) ? <MessageAction open={openForm} onChange={setOpenForm} /> : <LoginAction />}
      </Box>
      {Boolean(user) && !openForm && (
        <>
          <ShareAction sx={{ position: 'fixed', right: 24, bottom: 80, zIndex: 100 }} />
          <PaymentAction sx={{ position: 'fixed', right: 16, bottom: 8, zIndex: 100 }} />
        </>
      )}
      <AuthDialog />
      <Snackbar />
    </>
  )
}
