import { useCallback } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { Field, FieldButton, Form, useForm } from '@rpgtec/use-form'

import useApp from 'hooks/useApp'
import useAuth from 'hooks/useAuth'
import { ReactComponent as SendIcon } from 'icons/send.svg'
import { addDoc } from 'lib/firestore'

import { showMessage } from './Snackbar'

export default function MessageAction({ open, onChange }) {
  const form = useForm({ defaultValues: {} })
  const { toxicWords } = useApp()
  const { user } = useAuth()

  const handleSubmit = useCallback(
    data => {
      if (toxicWords.length > 0) {
        const regex = new RegExp(toxicWords.join('|'))
        if (Boolean(data.text.match(regex))) {
          showMessage({ text: 'コメントの送信に失敗しました', severity: 'error' })
          return
        }
      }
      const message = {
        data,
        type: 'text',
        user: { id: user.id, name: user.name },
      }
      if (user.blocked) {
        message.deleted = true
      }
      return addDoc('messages', message)
        .then(() => form.setValue('text', ''))
        .catch(() => showMessage({ text: 'コメントの送信に失敗しました', severity: 'error' }))
    },
    [form, user, toxicWords],
  )

  if (!open) {
    return (
      <Box sx={{ height: 40, p: 2 }}>
        <Button
          variant="outlined"
          sx={{
            borderRadius: 999,
            py: '10px',
            fontSize: 12,
          }}
          onClick={() => onChange(true)}
        >
          コメントをする
        </Button>
      </Box>
    )
  }

  return (
    <ClickAwayListener onClickAway={() => onChange(false)}>
      <Box sx={{ height: 40, p: 2 }}>
        <Form
          form={form}
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Field
            name="text"
            rules={{ required: true }}
            variant="outlined"
            errorText=""
            autoFocus
            placeholder="コメントをする"
            size="small"
            sx={{
              mr: 1,
              borderRadius: '4px',
              background: 'rgba(255,255,255,0.8)',
              flexGrow: 1,
              '& .MuiOutlinedInput-input': {
                color: 'primary.main',
                fontWeight: 'bold',
                borderRadius: '4px',
                borderStyle: 'solid',
                borderWidth: '2px',
                borderColor: 'primary.main',
                '&::placeholder': {
                  color: 'primary.main',
                  fontWeight: 'bold',
                  opacity: 0.8,
                },
              },
            }}
          />
          <Box display="flex" justifyContent="center">
            <FieldButton type="submit" icon={<SendIcon />} />
          </Box>
        </Form>
      </Box>
    </ClickAwayListener>
  )
}
