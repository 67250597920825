import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'

import useAuth, { sendEmailVerification } from 'hooks/useAuth'

const EmailStatus = Object.freeze({
  InProgress: 1,
  Success: 2,
  Error: 3,
})

// TODO: 送信中の UI はボタン上にプログレス表示
export default function SendVerifyEmail({ onClose }) {
  const [emailStatus, setEmailStatus] = useState(EmailStatus.InProgress)
  const { user } = useAuth()

  const handleVerifyEmail = () => {
    setEmailStatus(EmailStatus.InProgress)
    return sendEmailVerification()
      .then(() => setEmailStatus(EmailStatus.Success))
      .catch(() => setEmailStatus(EmailStatus.Error))
  }

  useEffect(() => {
    handleVerifyEmail()
  }, [])

  switch (emailStatus) {
    case EmailStatus.Success:
      return (
        <>
          <DialogTitle>認証メールを送信しました</DialogTitle>
          <Box sx={{ pb: 3, px: 3 }}>
            <Typography sx={{ pb: 2 }}>
              {user.email} に届いたメールのリンクを開いてログインを完了させて下さい。
            </Typography>
            <Typography sx={{ pb: 2 }}>
              しばらくしてもメールが届かない場合は以下をお試し下さい。
            </Typography>
            <Box display="flex" justifyContent="center">
              <Button onClick={onClose}>別のアドレスで登録</Button>
              <Button variant="contained" onClick={handleVerifyEmail}>
                メールの再送信
              </Button>
            </Box>
          </Box>
        </>
      )
    case EmailStatus.Error:
      return (
        <>
          <DialogTitle>認証メールの送信に失敗しました</DialogTitle>
          <Box sx={{ pb: 3, px: 3 }}>
            <Typography sx={{ pb: 2 }}>{user.email} への認証メール送信に失敗しました。</Typography>
            <Typography sx={{ pb: 2 }}>大変お手数ですが、以下をお試し下さい。</Typography>
            <Box display="flex" justifyContent="center">
              <Button onClick={onClose}>別のアドレスで登録</Button>
              <Button variant="contained" onClick={handleVerifyEmail}>
                メールの再送信
              </Button>
            </Box>
          </Box>
        </>
      )
    default:
      return null
  }
}
