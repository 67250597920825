import { ref, uploadString } from 'firebase/storage'

import { storage } from './firebase'

const project = 'the-pink'

export const upload = (path, data, metadata = 'data_url') => {
  return uploadString(ref(storage, path), data, metadata).then(() => getUrl(path))
}

export const getUrl = _path => {
  const path = encodeURIComponent(_path)
  return process.env.NODE_ENV === 'development'
    ? `http://localhost:9199/v0/b/${project}.appspot.com/o/${path}?alt=media`
    : `https://firebasestorage.googleapis.com/v0/b/${project}.appspot.com/o/${path}?alt=media`
}
