import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'

import useApp from 'hooks/useApp'
import { ReactComponent as CloseIcon } from 'icons/close.svg'
import placeholder from 'images/placeholder.jpg'
import { logEvent } from 'lib/analytics'
import { getUrl } from 'lib/storage'
import { formatPrice } from 'utils'

import ProductDialog from './ProductDialog'

export default function ProductList({ onClose }) {
  const [open, setOpen] = useState(false)
  const [successItem, setSuccessItem] = useState(null)
  const [selected, setSelected] = useState(null)
  const data = useApp()
  const products = [
    {
      id: 'berry-card',
      name: 'BERRYカード',
      price: 1000,
      image: getUrl('cards/berry-normal.jpg'),
      items: [
        { amount: 10, name: 'レアBERRYカード', image: getUrl('cards/berry-rare.jpg') },
        { amount: 30, name: 'バケツBERRYカード', image: getUrl('cards/berry-bucket.jpg') },
        { amount: 100, name: 'アタッシュBERRYカード', image: getUrl('cards/berry-attache.jpg') },
        { amount: 300, name: '木箱BERRYカード', image: getUrl('cards/berry-box.jpg') },
        {
          amount: 1000,
          name: 'スーパーアタッシュBERRYカード',
          image: getUrl('cards/berry-super.jpg'),
        },
      ],
      amount: 1000,
    },
    ...data.products,
  ]

  useEffect(() => {
    setOpen(true)
  }, [])

  const handleSuccess = item => {
    setOpen(false)
    setSuccessItem(item)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionProps={{ onExited: () => onClose(successItem) }}
        fullScreen
      >
        <IconButton
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: theme => theme.spacing(1),
            top: theme => theme.spacing(1),
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>プレゼント選択</DialogTitle>
        <List>
          {products.map((x, i) => (
            <ProductListItem
              key={i}
              product={x}
              onClick={() => {
                setSelected(x)
                logEvent('payment', { type: 'open-item', name: x.name, price: x.price })
              }}
            />
          ))}
        </List>
      </Dialog>
      {Boolean(selected) && (
        <ProductDialog
          product={selected}
          onSuccess={handleSuccess}
          onClose={() => setSelected(null)}
        />
      )}
    </>
  )
}

const ProductListItem = ({ product, onClick }) => (
  <ListItem button sx={{ py: '12px' }} onClick={onClick}>
    <img
      style={{ width: 48, height: 48, objectFit: 'cover', background: '#eee' }}
      src={product.image}
      alt={product.name}
      onError={event => (event.target.src = placeholder)}
    />
    <Box sx={{ mx: 2 }}>
      <Typography color="textSecondary" sx={{ fontSize: 10, mb: 0.5 }}>
        {product.name}
      </Typography>
      <Typography sx={{ fontWeight: 'bold' }}>{formatPrice(product.price)}円</Typography>
    </Box>
  </ListItem>
)
