import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import Loading from 'components/Loading'
import useApp from 'hooks/useApp'
import useAuth from 'hooks/useAuth'

import Live from './Live'

const Admin = lazy(() => import('./Admin'))

export default function Index() {
  const { search } = useLocation()
  const { initialized } = useAuth()
  const { loaded } = useApp()

  if (!initialized || !loaded) {
    return <Loading />
  }

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/live" component={Live} />
        <Route path="/admin" component={Admin} />
        <Redirect to={{ pathname: '/live', search }} />
      </Switch>
    </Suspense>
  )
}
