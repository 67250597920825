import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import {
  EmailAuthProvider,
  sendEmailVerification as _sendEmailVerification,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  signOut as _signOut,
  updateProfile as _updateProfile,
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  updatePassword,
  verifyPasswordResetCode,
} from 'firebase/auth'

import { auth } from 'lib/firebase'
import { serverTimestamp, setDoc, updateDoc, useDoc } from 'lib/firestore'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [firebaseUser, setFirebaseUser] = useState({ data: null, initialized: false })
  const { item: user } = useDoc(`users/${Boolean(firebaseUser.data) ? firebaseUser.data.uid : ''}`)

  useEffect(() => {
    return onAuthStateChanged(auth, user => {
      setFirebaseUser({ data: user, initialized: true })
      if (user && user.emailVerified) {
        updateDoc(`sessions/${user.uid}`, { loginAt: serverTimestamp() })
      }
    })
  }, [])

  const createUser = useCallback(
    name => {
      const data = {
        name,
        email: firebaseUser.data.email,
        totalAmount: 0,
      }
      return setDoc(`users/${firebaseUser.data.uid}`, data)
    },
    [firebaseUser],
  )

  const signUp = useCallback((email, password) => {
    return createUserWithEmailAndPassword(auth, email, password).catch(error => {
      let message = ''
      switch (error.code) {
        case 'auth/email-already-in-use':
          message = '既に登録されたメールアドレスです。'
          break
        case 'auth/invalid-email':
          message = 'メールアドレスの形式で入力してください'
          break
        default:
          console.log(error)
          message = '登録に失敗しました、大変お手数ですが時間を空けてお試しください。'
      }
      throw new Error(message)
    })
  }, [])

  const verifyEmail = useCallback(
    actionCode => {
      return applyActionCode(auth, actionCode).then(() =>
        updateDoc(`users/${user.id}`, { emailVerified: true }).then(() =>
          setDoc(`sessions/${user.id}`, { name: user.name, loginAt: serverTimestamp() }),
        ),
      )
    },
    [user],
  )

  const signIn = useCallback((email, password) => {
    return signInWithEmailAndPassword(auth, email, password).catch(error => {
      let message = ''
      switch (error.code) {
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          message = 'メールアドレスまたはパスワードが正しくありません。'
          break
        default:
          console.log(error)
          message = 'ログインに失敗しました、大変お手数ですが時間を空けてお試しください。'
      }
      throw new Error(message)
    })
  }, [])

  const signOut = useCallback(() => {
    return _signOut(auth)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        createUser,
        initialized: firebaseUser.initialized,
        verifyEmail,
        signIn,
        signOut,
        signUp,
        user,
      }}
      children={children}
    />
  )
}

export default function useAuth() {
  return useContext(AuthContext)
}

export const sendPasswordResetEmail = email => {
  return _sendPasswordResetEmail(auth, email).catch(error => {
    let message = ''
    switch (error.code) {
      case 'auth/user-not-found':
        message =
          '入力されたメールアドレスは登録されていません。新規登録からアカウントを作成してください'
        break
      default:
        console.log(error)
        message =
          'リセット用メールの送信に失敗しました、大変お手数ですが時間を空けてお試しください。'
    }
    throw new Error(message)
  })
}

export const sendEmailVerification = email => {
  const _auth = getAuth()
  return _sendEmailVerification(_auth.currentUser).catch(error => {
    console.log(error)
    throw new Error('認証メールの送信に失敗しました、大変お手数ですが時間を空けてお試しください。')
  })
}

export const updateProfile = (displayName, photoURL = '') => {
  return _updateProfile(auth.currentUser, { displayName, photoURL })
}

export const resetPassword = async (actionCode, newPassword) => {
  try {
    const email = await verifyPasswordResetCode(auth, actionCode)
    await confirmPasswordReset(auth, actionCode, newPassword)
    return { email, password: newPassword }
  } catch (error) {
    throw new Error(error)
  }
}

export const changePassword = ({ currentPassword, newPassword }) => {
  const user = auth.currentUser
  const credential = EmailAuthProvider.credential(user.email, currentPassword)
  return reauthenticateWithCredential(user, credential).then(() =>
    updatePassword(user, newPassword),
  )
}
