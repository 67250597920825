import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Dialog from '@mui/material/Dialog'

import useAuth from 'hooks/useAuth'
import useQueryString from 'hooks/useQueryString'

import ChangePassword from './ChangePassword'
import ForgotPassword from './ForgotPassword'
import Login from './Login'
import Nickname from './Nickname'
import ResetPassword from './ResetPassword'
import SendVerifyEmail from './SendVerifyEmail'
import VerifyEmail from './VerifyEmail'

const Mode = Object.freeze({
  SignUp: 'signup',
  SignIn: 'signin',
  SignOut: 'signout',
  SendVerifyEmail: 'sendVerifyEmail',
  VerifyEmail: 'verifyEmail',
  ForgotPassword: 'forgotPassword',
  ResetPassword: 'resetPassword',
  ChangePassword: 'changePassword',
  Nickname: 'nickname',
  Hidden: 'hidden',
})

export default function AuthDialog() {
  const history = useHistory()
  const { mode, oobCode } = useQueryString()
  const [open, setOpen] = useState(false)
  const { user, signOut } = useAuth()

  useEffect(() => {
    if (Boolean(mode) && mode !== Mode.Hidden) {
      setOpen(true)
    }
  }, [mode])

  const handleChange = useCallback(
    value => {
      if (mode !== value) {
        history.replace(`?mode=${value}`)
      }
    },
    [history, mode],
  )

  useEffect(() => {
    if (!user) return
    if (mode === Mode.SignOut) {
      return signOut().then(() => setOpen(false))
    }
    if (!user.name) {
      return handleChange(Mode.Nickname)
    }
    if (!user.emailVerified && mode !== Mode.VerifyEmail) {
      return handleChange(Mode.SendVerifyEmail)
    }
    if (user.emailVerified && mode === Mode.SendVerifyEmail) {
      return setOpen(false)
    }
  }, [user, mode, handleChange, signOut])

  const handleExited = () => {
    history.replace('/live')
  }

  const DialogContent = useCallback(() => {
    switch (mode) {
      case Mode.SignUp:
      case Mode.Login:
        return (
          <Login
            login={mode === Mode.Login}
            onClose={() => setOpen(false)}
            onSignIn={() => setOpen(false)}
            onSignUp={() => handleChange(Mode.Nickname)}
            onClickForgotPassword={() => handleChange(Mode.ForgotPassword)}
          />
        )
      case Mode.SendVerifyEmail:
        return <SendVerifyEmail onClose={() => handleChange(Mode.SignUp)} />
      case Mode.VerifyEmail:
        return (
          <VerifyEmail
            oobCode={oobCode}
            onClickResend={() => handleChange(Mode.SendVerifyEmail)}
            onSuccess={() => setOpen(false)}
          />
        )
      case Mode.ForgotPassword:
        return <ForgotPassword onClose={() => handleChange(Mode.SignIn)} />
      case Mode.ResetPassword:
        return <ResetPassword onSuccess={() => setOpen(false)} onClose={() => setOpen(false)} />
      case Mode.ChangePassword:
        return <ChangePassword onSuccess={() => setOpen(false)} />
      case Mode.Nickname:
        return (
          <Nickname
            onCreateUser={() => handleChange(Mode.SendVerifyEmail)}
            onUpdateUser={() => setOpen(false)}
          />
        )
      default:
        return null
    }
  }, [oobCode, mode, handleChange])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: handleExited }}
      sx={{ '& .MuiDialog-paper': { width: 420 } }}
    >
      <DialogContent />
    </Dialog>
  )
}
