import { useEffect, useState } from 'react'

import Alert from '@mui/material/Alert'
import Slide from '@mui/material/Slide'
import MuiSnackbar from '@mui/material/Snackbar'

const EVENT = 'snackbarMessage'

export default function Snackbar() {
  const [message, setMessage] = useState({})

  useEffect(() => {
    const handleMessage = event => setMessage(event.detail)

    window.addEventListener(EVENT, handleMessage)
    return () => window.removeEventListener(EVENT, handleMessage)
  }, [])

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(message.text)}
      autoHideDuration={3000}
      onClose={() => setMessage({})}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'left' }}
      sx={{ mt: '72px' }}
    >
      <Alert
        onClose={() => setMessage({})}
        variant="filled"
        severity={message.severity || 'success'}
        sx={{ bgcolor: '#323232' }}
      >
        {message.text}
      </Alert>
    </MuiSnackbar>
  )
}

export const showMessage = message => {
  const event = new CustomEvent(EVENT, { detail: message })
  window.dispatchEvent(event)
}
