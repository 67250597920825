import { useLayoutEffect, useRef } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import PaymentMessage from 'components/PaymentMessage'
import useAuth from 'hooks/useAuth'
import { orderBy, useDocs, where } from 'lib/firestore'
import { scrollToBottom } from 'utils'

const date = dayjs().add(-1, 'hour').toDate()

export default function MessageList({ sx }) {
  const { user } = useAuth()
  const ref = useRef(null)
  const { items, isFirstLoad } = useDocs(
    'messages',
    where('createdAt', '>', date),
    orderBy('createdAt', 'asc'),
  )
  const messages = (items || []).filter(x => !(x.deleted && x.user.id !== (user || {}).id))

  useLayoutEffect(() => {
    const { scrollHeight, clientHeight, scrollTop } = ref.current
    if (items === null) return

    // 初回データ読み込み時
    if (isFirstLoad) {
      scrollToBottom(ref.current)
    } else if (
      scrollTop + clientHeight + 80 > scrollHeight || // スクロール位置が最下部の場合
      (user && items[items.length - 1].user.id === user.id) // 最後の投稿が自分の投稿の場合
    ) {
      scrollToBottom(ref.current, 'smooth')
    }
  }, [items, isFirstLoad, user])

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-end" sx={{ ...sx }}>
      <Box
        ref={ref}
        sx={{
          px: 2,
          py: 1,
          '&::-webkit-scrollbar': { display: 'none' },
          overflow: 'scroll',
        }}
      >
        {messages.map(x => (
          <Item key={x.id} data={x} sx={{ mb: 0.5 }} />
        ))}
      </Box>
    </Box>
  )
}

const Item = props => {
  switch (props.data.type) {
    case 'text':
      return <TextMessage {...props} />
    case 'payment':
      return <PaymentMessage {...props} />
    case 'join':
      return <JoinMessage {...props} />
    default:
      return null
  }
}

const TextMessage = ({ data, sx }) => (
  <Typography
    noWrap
    sx={{
      fontSize: 10,
      color: '#fff',
      textShadow: '0px 0px 2px rgba(0, 0, 0, 0.3), 0px 0.5px 1px rgba(0, 0, 0, 0.5)',
      lineHeight: '16px',
      p: 0.5,
      ...sx,
    }}
  >
    <strong>{data.user.name}</strong>
    <br />
    <span>{data.data.text}</span>
  </Typography>
)

const JoinMessage = ({ data, sx }) => (
  <Typography
    noWrap
    sx={{
      fontSize: 10,
      color: '#fff',
      textShadow: '0px 0px 2px rgba(0, 0, 0, 0.3), 0px 0.5px 1px rgba(0, 0, 0, 0.5)',
      lineHeight: '16px',
      p: 0.5,
      ...sx,
    }}
  >
    <span style={{ fontWeight: 'bold' }}>{data.user.name}さん</span>
    <span style={{ marginLeft: 4 }}>が参加しました</span>
  </Typography>
)
