import { useCallback, useState } from 'react'

import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'
import { Field, FieldButton, Form, useForm } from '@rpgtec/use-form'

import useAuth from 'hooks/useAuth'
import { updateDoc } from 'lib/firestore'

export default function Nickname({ onCreateUser, onUpdateUser }) {
  const [error, setError] = useState(false)
  const { user, createUser } = useAuth()
  const form = useForm({ defaultValues: { name: Boolean(user && user.name) ? user.name : '' } })

  const handleSubmit = useCallback(
    values => {
      setError(null)
      if (user && user.name) {
        return updateDoc(`users/${user.id}`, values).then(onUpdateUser).catch(setError)
      } else {
        return createUser(values.name).then(onCreateUser).catch(setError)
      }
    },
    [onCreateUser, onUpdateUser, createUser, user],
  )

  return (
    <>
      <DialogTitle>ニックネーム</DialogTitle>
      <Typography sx={{ px: 3, pb: 3 }}>
        コメント時に表示されるニックネームをいれてください。
      </Typography>
      <Form
        form={form}
        onSubmit={handleSubmit}
        sx={{
          px: 3,
          pb: 3,
          '& .MuiTextField-root': { mb: 2 },
        }}
      >
        <Field
          name="name"
          label="ニックネーム"
          rules={{ required: true, maxLength: 20 }}
          errorText={{
            required: '必須項目です',
            maxLength: '20 文字以内で入力して下さい',
          }}
          autoFocus
        />
        {error && (
          <Box sx={{ mb: 2 }}>
            <FormHelperText error>{error.message}</FormHelperText>
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <FieldButton type="submit" variant="contained">
            {Boolean(user && user.name) ? '変更する' : '登録する'}
          </FieldButton>
        </Box>
      </Form>
    </>
  )
}
