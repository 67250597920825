import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { AppProvider } from 'hooks/useApp'
import { AuthProvider } from 'hooks/useAuth'
import ThemeProvider from 'Live/Theme'

import App from './App'

ReactDOM.render(
  <ThemeProvider>
    <AuthProvider>
      <AppProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppProvider>
    </AuthProvider>
  </ThemeProvider>,
  document.getElementById('root')
)
