import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export default function PaymentMessage({ data, sx }) {
  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'inline-block',
          background: 'rgba(0,0,0,.7)',
          borderRadius: '14px',
          py: 0.75,
          px: 2,
          '& .MuiTypography-root': {
            fontSize: 10,
            lineHeight: '16px',
            color: '#fff',
          },
        }}
      >
        <Typography sx={{ mb: 0.5 }}>
          <span style={{ fontWeight: 'bold', marginRight: 8 }}>{data.user.name}さん</span>
          <span>から</span>
        </Typography>
        <Typography>
          <span style={{ fontSize: 12, lineHeight: '16px', fontWeight: 'bold', marginRight: 2 }}>
            {data.data.name}
          </span>
          <span>が入りました✨</span>
        </Typography>
      </Box>
    </Box>
  )
}
