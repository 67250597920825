import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'

export default function Loading() {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 2000,
      }}
    >
      <CircularProgress />
    </div>
  )
}
