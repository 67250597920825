import { useCallback, useState } from 'react'

import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import FormHelperText from '@mui/material/FormHelperText'
import { Field, FieldButton, Form, useForm } from '@rpgtec/use-form'

import { changePassword } from 'hooks/useAuth'

export default function ChangePassword({ onSuccess }) {
  const [error, setError] = useState(false)
  const form = useForm({ defaultValues: {} })

  const handleSubmit = useCallback(
    values => {
      setError(null)
      return changePassword(values).then(onSuccess).catch(setError)
    },
    [onSuccess],
  )

  return (
    <>
      <DialogTitle>パスワード変更</DialogTitle>
      <Form
        form={form}
        onSubmit={handleSubmit}
        sx={{
          px: 3,
          pb: 3,
          '& .MuiTextField-root': { mb: 2 },
        }}
      >
        <Field
          name="currentPassword"
          type="password"
          label="現在のパスワード"
          rules={{ required: true, minLength: 8 }}
          errorText={{
            required: '必須項目です',
            minLength: '8 文字以上で入力して下さい',
          }}
          autoFocus
        />
        <Field
          name="newPassword"
          type="password"
          label="新しいパスワード"
          rules={{ required: true, minLength: 8 }}
          errorText={{
            required: '必須項目です',
            minLength: '8 文字以上で入力して下さい',
          }}
        />
        {error && (
          <Box sx={{ mb: 2 }}>
            <FormHelperText error>{error.message}</FormHelperText>
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <FieldButton type="submit" variant="contained">
            変更する
          </FieldButton>
        </Box>
      </Form>
    </>
  )
}
