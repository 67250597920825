import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { setStorage, useStorage } from '@rpgtec/use-storage'
import Player from '@vimeo/player'

import useQueryString from 'hooks/useQueryString'

export default function Vimeo({ objectFit = 'cover' }) {
  const [muted, setMuted] = useStorage('muted', true)
  const { mode } = useQueryString()
  const [open, setOpen] = useState(mode !== 'verifyEmail') // verifyEmail の時は確認後のダイアログでミュート解除

  useEffect(() => {
    const player = new Player(document.getElementById('vimeo'))
    player.getMuted().then(setMuted)
  }, [setMuted])

  const handleClick = async () => {
    setMute(!muted)
    setOpen(false)
  }

  return (
    <>
      <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, overflow: 'hidden' }}>
        <iframe
          id="vimeo"
          title="thepinkLive"
          src="https://vimeo.com/event/1199574/embed/4f97c98094?background=1"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={
            objectFit === 'cover'
              ? {
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  width: '177.77777778vh',
                  height: '56.25vw',
                  minWidth: '100%',
                  minHeight: '100%',
                  transform: 'translate(-50%, -50%)',
                }
              : {
                  width: '100vw',
                  height: '100vh',
                }
          }
        ></iframe>
      </Box>
      <Dialog open={open}>
        <DialogTitle>ライブ配信が始まりました！</DialogTitle>
        <DialogContent>ライブに参加してご視聴をお楽しみください</DialogContent>
        <DialogActions>
          <Button onClick={handleClick}>参加する</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export async function setMute(muted) {
  const element = document.getElementById('vimeo')
  if (!element) return
  const player = new Player(element)
  const _muted = await player.setMuted(muted)
  setStorage('muted', _muted)
  if (!_muted) {
    await player.setVolume(1)
  }
}
