export const formatPrice = price => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const arrayEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b)
}

export const scrollToBottom = (target = window, behavior = 'instant') => {
  const { scrollHeight, clientHeight } = target === window ? document.documentElement : target
  target.scroll({ top: scrollHeight - clientHeight, behavior })
}

export const generateDateArray = (start, end) => {
  const dates = []
  for (let i = start; i <= end; i = new Date(i.getTime() + 24 * 60 * 60 * 1000)) {
    dates.push(i)
  }
  return dates
}
