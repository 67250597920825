export default function ProductIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.55341 6H2C0.895432 6 0 6.89543 0 8V11C0 11.7403 0.402198 12.3866 1 12.7324L1 20C1 21.6569 2.34314 23 4 23H11H12H13H20C21.6569 23 23 21.6569 23 20V12.7324C23.5978 12.3866 24 11.7403 24 11V8C24 6.89543 23.1046 6 22 6H20.4466C21.104 5.18357 21.2853 3.99934 20.6922 2.96133C19.8282 1.44931 17.7797 1.13896 16.5066 2.3272L13.7003 4.9464C13.3475 4.37828 12.7179 4 12 4C11.2821 4 10.6525 4.37828 10.2997 4.9464L7.49341 2.3272C6.22029 1.13896 4.17183 1.44931 3.30782 2.96133C2.71468 3.99934 2.89597 5.18357 3.55341 6ZM20 22H13V13H22V20C22 21.1046 21.1046 22 20 22ZM11 22V13H2V20C2 21.1046 2.89543 22 4 22H11ZM22 12H13V7.00002H18.3484L18.3586 7H22C22.5523 7 23 7.44771 23 8V11C23 11.5518 22.5531 11.9992 22.0014 12L22 12ZM11 12V7.00002H5.65156L5.64127 7H2C1.44772 7 1 7.44771 1 8L1 11C1 11.5518 1.44698 11.9993 1.99864 12L2 12H11ZM9.96296 6L5.64339 6C4.34331 5.99413 3.53003 4.58803 4.17606 3.45747C4.72 2.50558 6.0096 2.3102 6.81109 3.05825L9.96296 6ZM14.037 6L18.3565 6C19.6566 5.99423 20.47 4.58807 19.8239 3.45747C19.28 2.50558 17.9904 2.3102 17.1889 3.05825L14.037 6Z"
        fill={color}
      />
    </svg>
  )
}
