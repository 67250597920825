import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useStorage } from '@rpgtec/use-storage'

import useApp from 'hooks/useApp'
import useAuth from 'hooks/useAuth'
import SettingIcon from 'icons/SettingIcon'
import logo from 'images/logo.png'

import { setMute } from './Vimeo'

export default function AppBar({ sx }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [muted] = useStorage('muted', true)
  const { user, signOut } = useAuth()
  const { event } = useApp()
  const history = useHistory()

  const handleMenu = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSignOut = () => {
    signOut()
    setAnchorEl(null)
  }

  const handleClickNickname = () => {
    history.push('/live?mode=nickname')
    setAnchorEl(null)
  }

  const handleClickChangePassword = () => {
    history.push('/live?mode=changePassword')
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          p: 1,
          height: 56,
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.54) 3%, rgba(0, 0, 0, 0.42) 36%, rgba(0, 0, 0, 0.249316) 74%, rgba(0, 0, 0, 0) 100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        <img
          src={logo}
          onClick={() => window.open('http://the-pink.club', '_blank')}
          alt="thepinkLive"
          width="56"
          height="56"
        />
        <Typography noWrap sx={{ flex: 1, ml: 0.5, color: '#fff', fontWeight: 'bold' }}>
          {Boolean(event) ? event.title : 'thepinkLive'}
        </Typography>
        <IconButton onClick={() => setMute(!muted)}>
          {event &&
            event.streaming &&
            (muted ? (
              <VolumeOffIcon style={{ color: '#fff' }} />
            ) : (
              <VolumeUpIcon style={{ color: '#fff' }} />
            ))}
        </IconButton>
        {user && (
          <Box>
            <IconButton onClick={handleMenu}>
              <SettingIcon color="#fff" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorPosition={{ left: 0, top: 100 }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {user.admin && <MenuItem onClick={() => history.push('/admin')}>管理画面</MenuItem>}
              <MenuItem onClick={handleClickNickname}>ユーザー名変更</MenuItem>
              <MenuItem onClick={handleClickChangePassword}>パスワード変更</MenuItem>
              <MenuItem onClick={handleSignOut}>ログアウト</MenuItem>
            </Menu>
          </Box>
        )}
      </Box>
    </>
  )
}
