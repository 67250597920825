import { useCallback, useState } from 'react'

import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Field, FieldButton, Form, useForm } from '@rpgtec/use-form'

import useAuth from 'hooks/useAuth'
import { ReactComponent as CloseIcon } from 'icons/close.svg'

export default function Login({ login, onClose, onSignIn, onSignUp, onClickForgotPassword }) {
  const [loginMode, setLoginMode] = useState(typeof login === 'boolean' ? login : false)
  const [error, setError] = useState(null)
  const form = useForm({ defaultValues: {} })
  const { signIn, signUp } = useAuth()

  const handleSignIn = useCallback(
    ({ email, password }) => {
      setError(null)
      return signIn(email, password).then(onSignIn).catch(setError)
    },
    [signIn, onSignIn],
  )

  const handleSignUp = useCallback(
    ({ email, password }) => {
      setError(null)
      return signUp(email, password).then(onSignUp).catch(setError)
    },
    [signUp, onSignUp],
  )

  const toggleMode = useCallback(() => {
    setLoginMode(current => !current)
  }, [])

  return (
    <>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: theme => theme.spacing(3),
          top: theme => theme.spacing(3),
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>{loginMode ? 'ログイン' : '新規登録'}</DialogTitle>
      <Form
        form={form}
        onSubmit={loginMode ? handleSignIn : handleSignUp}
        sx={{
          px: 3,
          pb: 4,
          '& .MuiTextField-root': { mb: 2 },
        }}
      >
        <Field
          name="email"
          label="メールアドレス"
          rules={{
            required: true,
            pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          }}
          errorText={{
            required: '必須項目です',
            pattern: 'メールアドレスの形式で入力してください',
          }}
        />
        <Field
          name="password"
          type="password"
          label="パスワード"
          rules={{ required: true, minLength: 8 }}
          errorText={{
            required: '必須項目です',
            minLength: '8 文字以上で入力して下さい',
          }}
        />
        {error && (
          <Box sx={{ mb: 2 }}>
            <FormHelperText error>{error.message}</FormHelperText>
          </Box>
        )}
        {!loginMode && (
          <Box sx={{ textAlign: 'center', my: 1 }}>
            <Field
              name="agree"
              label={
                <Typography>
                  <Typography variant="link" onClick={() => window.open('/terms/', '_blank')}>
                    利用規約
                  </Typography>
                  に同意する
                </Typography>
              }
              type="checkbox"
            />
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <FieldButton
            type="submit"
            variant="contained"
            disabled={!loginMode && !form.watch('agree')}
          >
            {loginMode ? 'ログイン' : '登録する'}
          </FieldButton>
        </Box>
      </Form>
      <Box sx={{ px: 3, pb: 3, textAlign: 'center' }}>
        {loginMode && (
          <Typography sx={{ mb: 2 }}>
            パスワードを忘れた方は
            <Typography variant="link" onClick={onClickForgotPassword}>
              こちら
            </Typography>
          </Typography>
        )}
        <Typography>
          {loginMode ? '新規登録は' : 'アカウントがある方は'}
          <Typography variant="link" onClick={toggleMode}>
            こちら
          </Typography>
        </Typography>
      </Box>
    </>
  )
}
