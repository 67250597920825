import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'

import { setStorage } from '@rpgtec/use-storage'
import {
  Timestamp,
  addDoc as _addDoc,
  deleteDoc as _deleteDoc,
  getDoc as _getDoc,
  getDocs as _getDocs,
  setDoc as _setDoc,
  updateDoc as _updateDoc,
  where as _where,
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
} from 'firebase/firestore'

import { arrayEqual } from 'utils'

import { db } from './firebase'

const format = data => {
  let _data = {}
  Object.entries(data).forEach(([key, value]) => {
    if (value instanceof Date) {
      _data[key] = Timestamp.fromDate(value)
    } else {
      _data[key] = value
    }
  })
  return _data
}

const parseDoc = doc => {
  const data = doc.data({ serverTimestamps: 'estimate' })

  if (!data) throw new Error('Data not found')

  const parse = object => {
    Object.entries(object).forEach(([key, value]) => {
      if (value instanceof Timestamp) {
        object[key] = value.toDate()
      } else if (value instanceof Object) {
        if (Array.isArray(value)) {
          // Array
          object[key] = value.map(x => {
            parse(x)
            return x
          })
        } else {
          // Object
          parse(object[key])
        }
      }
    })
  }
  parse(data)

  return { ...data, id: doc.id }
}

export const addDoc = (path, data) => {
  return _addDoc(collection(db, path), format({ ...data, createdAt: serverTimestamp() }))
}

export const setDoc = (path, data) => {
  return _setDoc(doc(db, path), format({ ...data, createdAt: serverTimestamp() }))
}

export const updateDoc = (path, data) => {
  return _updateDoc(doc(db, path), format({ ...data, updatedAt: serverTimestamp() }))
}

export const deleteDoc = path => {
  return _deleteDoc(doc(db, path))
}

export const getDoc = path => {
  return _getDoc(doc(db, path)).then(parseDoc)
}

export const getDocs = (path, ...conditions) => {
  return _getDocs(query(collection(db, path), ...conditions)).then(({ docs }) => docs.map(parseDoc))
}

const validateDocPath = path => {
  const elements = path.split('/')
  if (elements.length % 2 !== 0) return false
  const hasEmptyString = elements.some(x => x === '')
  return !hasEmptyString
}

export const useDoc = path => {
  const reducer = (x, y) => ({ ...x, ...y })
  const [_path, setPath] = useState(path)
  const [data, setData] = useReducer(reducer, { item: null, error: null, loading: true })

  useEffect(() => {
    if (_path !== path) {
      setPath(path)
    }
  }, [path, _path])

  useEffect(() => {
    if (validateDocPath(_path)) {
      setData({ loading: true })
      return onSnapshot(doc(db, _path), {
        next: doc => {
          try {
            const item = parseDoc(doc)
            setData({ item, error: null, loading: false })
          } catch (error) {
            setData({ error, loading: false })
          }
        },
        error: error => setData({ error }),
      })
    } else {
      setData({ item: null, error: { message: 'invalid doc path' }, loading: false })
    }
  }, [_path])

  return data
}

export const useDocs = (path, ...conditions) => {
  const reducer = (x, y) => ({ ...x, count: x.count + 1, ...y })
  const [{ items, count, more, error, loading }, setData] = useReducer(reducer, {
    items: null,
    count: 0,
    more: [],
    error: null,
    loading: true,
  })
  const [[_path, _conditions], setQuery] = useState([path, conditions])
  const mergedItems = useMemo(() => (items !== null ? [...items, ...more] : []), [items, more])

  useEffect(() => {
    setStorage('loading', loading && items === null)
  }, [loading, items])

  useEffect(() => {
    if (path !== _path || !arrayEqual(conditions, _conditions)) {
      setQuery([path, conditions])
      setData({ count: 0, more: [] })
    }
  }, [path, conditions, _path, _conditions])

  useEffect(() => {
    return onSnapshot(query(collection(db, _path), ..._conditions), {
      next: ({ docs }) => setData({ items: docs.map(parseDoc), loading: false, error: null }),
      error: error => {
        console.log(error)
        setData({ error, loading: false })
      },
    })
  }, [_path, _conditions])

  const next = useCallback(() => {
    if (mergedItems.length === 0) {
      throw new Error('Cannot load next items')
    }
    setData({ loading: true })
    return getDocs(
      _path,
      ..._conditions,
      startAfter(mergedItems[mergedItems.length - 1].createdAt),
    ).then(res => setData({ more: [...more, ...res], loading: false }))
  }, [mergedItems, _path, _conditions, more])

  return {
    items: mergedItems,
    isFirstLoad: count === 1,
    error,
    loading,
    next,
  }
}

export const generateId = path => {
  return doc(collection(db, path)).id
}

export const where = (field, operator, value) => {
  if (value instanceof Date) {
    value = Timestamp.fromDate(value)
  }
  return _where(field, operator, value)
}

export { orderBy, limit, serverTimestamp }
