import { useHistory } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

export default function LoginAction({ onClick }) {
  const history = useHistory()

  return (
    <Box
      sx={{
        height: 40,
        p: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        variant="contained"
        sx={{
          borderRadius: 999,
          py: '10px',
          fontSize: 12,
        }}
        onClick={() => history.push('?mode=signup')}
      >
        登録・ログイン
      </Button>
    </Box>
  )
}
