import { useState } from 'react'

import Fab from '@mui/material/Fab'

import ProductIcon from 'icons/ProductIcon'
import { logEvent } from 'lib/analytics'

import PaymentSuccessDialog from './PaymentSuccessDialog'
import ProductList from './ProductList'

export default function PaymentAction({ sx }) {
  const [open, setOpen] = useState(false)
  const [successItem, setSuccessItem] = useState(null)

  const handleClick = () => {
    setOpen(true)
    logEvent('payment', { type: 'open-list' })
  }

  const handleCloseList = item => {
    setOpen(false)
    setSuccessItem(item)
  }

  return (
    <>
      <Fab color="primary" style={{ ...sx }} onClick={handleClick}>
        <ProductIcon color="#fff" />
      </Fab>
      {open && <ProductList onClose={handleCloseList} />}
      {Boolean(successItem) && (
        <PaymentSuccessDialog item={successItem} onClose={() => setSuccessItem(null)} />
      )}
    </>
  )
}
