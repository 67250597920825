import { useCallback, useState } from 'react'

import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'
import { Field, FieldButton, Form, useForm } from '@rpgtec/use-form'

import useAuth, { resetPassword } from 'hooks/useAuth'
import useQueryString from 'hooks/useQueryString'

export default function ResetPassword({ onSuccess, onClose }) {
  const [error, setError] = useState(false)
  const { signIn } = useAuth()
  const form = useForm({ defaultValues: {} })
  const query = useQueryString()

  const handleSubmit = useCallback(
    ({ newPassword }) => {
      if (query.oobCode) {
        setError(null)
        return resetPassword(query.oobCode, newPassword)
          .then(({ email, password }) => signIn(email, password))
          .then(onSuccess)
          .catch(setError)
      }
    },
    [onSuccess, query, signIn]
  )

  return (
    <>
      <DialogTitle>パスワード再設定</DialogTitle>
      <Typography sx={{ px: 3, pb: 3 }}>新しいパスワードを入力して下さい。</Typography>
      <Form
        form={form}
        onSubmit={handleSubmit}
        sx={{
          px: 3,
          pb: 3,
          '& .MuiTextField-root': { mb: 2 },
        }}
      >
        <Field
          name="newPassword"
          type="password"
          label="新しいパスワード"
          rules={{ required: true, minLength: 8 }}
          errorText={{
            required: '必須項目です',
            minLength: '8 文字以上で入力して下さい',
          }}
          autoFocus
        />
        {error && (
          <Box sx={{ mb: 2 }}>
            <FormHelperText error>
              パスワードの再設定に失敗しました。大変お手数おかけしますが、再設定リンクを再度送信して下さい。
            </FormHelperText>
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <FieldButton type="submit" variant="contained">
            再設定する
          </FieldButton>
        </Box>
      </Form>
    </>
  )
}
